<template>
  <div class="filter">
    <div class="filter-btn"
         v-for="(n,i) in list"
         :key="i"
         :class="{'active':i==currentIndex}"
         @click="btnClick(i)">{{n.name}}</div>
  </div>
</template>

<script>
export default {
  name: "Filter",
  props: {
    list: {
      type: Array,
      default: [{name: '全部', id: '-1'}]
    }
  },
  data () {
    return {
      currentIndex: 0,
    }
  },
  methods: {
    btnClick (i) {
      this.currentIndex = i;
      this.$emit('filterData', this.list[i].id);
    }
  }
}
</script>

<style lang="scss" scoped>
.filter {
  width: 100%;
  float: left;
  padding: 16px;

  .filter-btn {
    float: left;
    height: 32px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    color: rgba(255, 255, 255, 0.8);
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    border-radius: 16px;
    margin: 0 12px 12px 0;
    font-size: 14px;

    &:hover {
      background: rgba(0, 130, 255, 0.6);
      color: #fff;
    }

    &.active {
      background: rgba(0, 130, 255, 1);
      color: #fff;
    }
  }
}
</style>